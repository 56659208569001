export default {
    public: {
        error: {
            title: 'Error',
            network: 'Network access error',
            execute: 'service execute error'
        },
        info: {
            title: "information",
        }
    },
    button: {
        refresh: 'Refresh',
        prev: 'Prev',
        next: 'Next',
        pay: 'Pay Now',
        yes: 'Yes',
        no: 'No',
        verify: 'Verify',
        select: 'Select',
        continue: 'Continue to payment',
        balance: 'Check Balance'
    },
    store: {
        title: {
            page: 'Select Location',
        },
        error: {
            getList: 'Error in get store list',
        }
    },
    room: {
        title: {
            page: 'Select Room',
            calendar: 'Pick a date'
        },
        button: {
            30: '30 mins',
            60: '1 hour',
            select: 'Select'
        },
        prompt: {
            roomInput: 'Please select a reservation room'
        },
        error: {
            getList: 'Error in get room list',
        }
    },
    time: {
        title: {
            page: 'Date & Time',
            calendar: 'Pick a date'
        },
        button: {
            30: '30 mins',
            60: '1 hour',
            select: 'Select'
        },
        prompt: {
            roomInput: 'Please select a reservation room'
        },
        error: {
            getBookTimes: 'Error in obtaining the scheduled time, please try a different date',
            timeContinuous: 'Please select a certain time or multiple consecutive times',
            timeOccupied: 'The appointment time has been occupied. Please change to another time'
        }
    },
    name: {
        title: {
            page: 'Contact Details'
        },
        label: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email Address',
            reEmail: 'Re-enter email',
            mobile: 'Mobile',
            teamName: 'Team Name',
            common1: 'Mandatory fields.',
            common2: "Note: If you've played with us before, please use the same team name and email address for cumulative game scores & ranking.",
            checkbox1: '(Optional) By ticking the box, I agree to receive marketing emails from Running Grid including updates and offers, promotion, etc.',
            checkbox2: 'By ticking the box, I have read, agreed and acknowledged',
            checkbox3: 'the Activity Participation Agreement and Waiver.'
        },
        error: {
            checkName: 'Team name already exists. Please choose another name.',
            checkEmail: "Email entered doesn't match. Please check.",
            agreement: 'You must agree the Activity Participation Agreement and Waiver and tick the box to proceed'
        },
        check: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email Address',
            reEmail: 'Re-enter email',
            mobile: 'Mobile',
            teamName: 'Team Name'
        }
    },
    people: {
        title: {
            page: 'Players Info'
        },
        label: {
            adult: 'Adults',
            child: 'Child (13 & under)',
            youth: 'Child (14 - 15)',
            student: 'Student',
            common1: 'Please Note:',
            common2: 'Maximum people per session: ',
            common3: 'Minimum people per session: ',
            common4: 'during weekdays'
        }
    },
    price: {
        title: {
            page: 'Payment'
        },
        error: {
            missBookTime: 'Missing appointment time',
            missPrice: 'The price for this combination is missing',
            missPin: 'PIN is required for gift card',
            timeOccupied: 'The appointment time has been occupied. Please change to another time',
            lackAdult: 'Child under 13 must be accompanied by an adult',
            limitTotal: 'The total number of people cannot exceed 6',
            limitWeekend: 'At least 3 people on weekends',
            limitWeekday: 'At least 2 people on weekdays',
            promoCode: 'Invalid or expired code, please try with a different code'
        }
    },
    pay: {
        title: {
            page: 'Payment'
        },
        error: {
            missBookTime: 'Missing appointment time',
            missPrice: 'The price for this combination is missing',
            timeOccupied: 'The appointment time has been occupied. Please change to another time',
            lackAdult: 'Child under 13 must be accompanied by an adult',
            limitTotal: 'The total number of people cannot exceed 6',
            limitWeekend: 'At least 3 people on weekends',
            limitWeekday: 'At least 2 people on weekdays'
        },
        tip: 'Note: You will be redirected to the payment gateway to enter your card details.'
    },
    thank: {
        title: {
            page: 'Thanks'
        },
        label: {
            thankInfo: 'Thanks for your booking',
            sendEmail: 'We will send you booking confirmation email, and game instructions'
        }
    },
    refund: {
        error: {}
    },
    card: {
        label: {
            firstName: 'First Name',
            email: 'Email Address',
            reEmail: 'Re-enter email',
            mobile: 'Mobile',
            message: 'Message to recipient (optional)',
            copyTo: 'Your email address',
            reCopyTo: 'Re-enter your email',
            number: 'Gift card number',
            pin: 'PIN',
            custom: 'Custom amount',
            balance: 'Your gift card balance is: NZD $',
        },
        checkbox: {
            copyTo: 'Send a copy to my email',
            agree1: 'By purchasing the gift card, I have read, agreed and acknowledged',
            agree2: 'gift card terms and conditions'
        },
        check: {
            firstName: 'First Name',
            email: 'Email Address',
            reEmail: 'Re-enter email',
            mobile: 'Mobile',
            copyTo: 'Your email address',
            reCopyTo: 'Re-enter your email',
        },
        error: {
            agree: "You must agree the Gift Card Terms and Conditions and tick the box to proceed",
            checkEmail: "Email entered doesn't match. Please check.",
            checkCopyTo: "Your email entered doesn't match. Please check.",
        }
    }
};
