<template>
    <div style="display:flex; flex-direction:column; height: 100%; background-image: url('img/rank.jpg');"
        class="div_background">

        <div style="text-align: center; padding: 15px 20px 0px 20px;">
            <img src="img/logo2.png" style="width: 260px; object-fit: cover;">
        </div>

        <div style="flex: 1; display: flex; flex-direction: row; justify-content: space-around; padding: 0px 20px;">
            <div style="display: flex; flex-direction: column; margin: 0 0px 50px 0px; width: 30%; font-size: 24px;">
                <div style="font-weight: 700; text-align: center; color: #e0efc8; margin-bottom: 10px;">
                    National Total Ranking
                </div>
                <div class="div_background"
                    style="font-size: 16px; background-image: url('img/frame_large.png'); flex: 1; padding: 20px 40px 20px 30px; display: flex; flex-direction: column; justify-content: space-around;">
                    <div v-for="rank in totalRanking" :key="rank.displayOrder"
                        style="display: flex; flex-direction: row; align-items: center;">
                        <div style="width: 30px; text-align: center;"> {{ rank.displayOrder }} </div>
                        <div style="width: 30px; display: flex; align-items: center; padding: 5px 5px 0px 5px;">
                            <img src="img/first.png" v-if="rank.displayOrder == 1"
                                style="width: 100%; height: 150%; object-fit: contain" />
                            <img src="img/second.png" v-if="rank.displayOrder == 2"
                                style="width: 100%; object-fit: contain" />
                            <img src="img/third.png" v-if="rank.displayOrder == 3"
                                style="width: 100%; object-fit: contain" />
                            <img src="img/other.png" v-if="rank.displayOrder > 3"
                                style="width: 100%; object-fit: contain" />
                        </div>

                        <div v-if="rank.teamName.length <= 25" style="flex: 1; margin-left: 5px;">{{ rank.teamName }}
                        </div>
                        <div v-else class="scroll-wrap" style="flex: 1; margin-left: 5px;">
                            <div class="scroll-item"> {{ rank.teamName }} </div>
                        </div>

                        <div style="width: 80px; text-align: end;">{{ rank.score }}</div>
                    </div>
                </div>
            </div>

            <div style="display: flex; flex-direction: column; margin: 50px 0px 50px 0px; width: 30%; font-size: 24px;">
                <div style="display: flex; flex-direction: column; height: 35%; width: 100%;">
                    <div style="font-weight: 700; text-align: center; color: #e0efc8; margin-bottom: 10px;">
                        National Ranking of The Day
                    </div>
                    <div class="div_background"
                        style="font-size: 16px; background-image: url('img/frame_small.png'); flex: 1; padding: 20px 40px 20px 30px; display: flex; flex-direction: column; justify-content: space-around;">
                        <div v-for="rank in dateRanking" :key="rank.displayOrder"
                            style="display: flex; flex-direction: row; align-items: center;">
                            <div style="width: 30px; text-align: center;"> {{ rank.displayOrder }} </div>
                            <div style="width: 30px; display: flex; align-items: center; padding: 5px 5px 0px 5px;">
                                <img src="img/first.png" v-if="rank.displayOrder == 1"
                                    style="width: 100%; height: 150%; object-fit: contain" />
                                <img src="img/second.png" v-if="rank.displayOrder == 2"
                                    style="width: 100%; object-fit: contain" />
                                <img src="img/third.png" v-if="rank.displayOrder == 3"
                                    style="width: 100%; object-fit: contain" />
                                <img src="img/other.png" v-if="rank.displayOrder > 3"
                                    style="width: 100%; object-fit: contain" />
                            </div>

                            <div v-if="rank.teamName.length <= 25" style="flex: 1; margin-left: 5px;">{{ rank.teamName
                                }}</div>
                            <div v-else class="scroll-wrap" style="flex: 1; margin-left: 5px;">
                                <div class="scroll-item"> {{ rank.teamName }} </div>
                            </div>

                            <div style="width: 80px; text-align: end;">{{ rank.score }}</div>
                        </div>
                    </div>
                </div>

                <div style="height: 5%; width: 100%;" />

                <div style="display: flex; flex-direction: column; flex:1; width: 100%;">
                    <div style="font-weight: 700; text-align: center; color: #e0efc8; margin-bottom: 10px;">
                        {{ storeName }} Ranking
                    </div>
                    <div class="div_background"
                        style="font-size: 16px; background-image: url('img/frame_middle.png'); flex: 1; padding: 20px 40px 20px 30px; display: flex; flex-direction: column; justify-content: space-around;">
                        <div v-for="rank in storeRanking" :key="rank.displayOrder"
                            style="display: flex; flex-direction: row; align-items: center;">
                            <div style="width: 30px; text-align: center;"> {{ rank.displayOrder }} </div>
                            <div style="width: 30px; display: flex; align-items: center; padding: 5px 5px 0px 5px;">
                                <img src="img/first.png" v-if="rank.displayOrder == 1"
                                    style="width: 100%; height: 150%; object-fit: contain" />
                                <img src="img/second.png" v-if="rank.displayOrder == 2"
                                    style="width: 100%; object-fit: contain" />
                                <img src="img/third.png" v-if="rank.displayOrder == 3"
                                    style="width: 100%; object-fit: contain" />
                                <img src="img/other.png" v-if="rank.displayOrder > 3"
                                    style="width: 100%; object-fit: contain" />
                            </div>

                            <div v-if="rank.teamName.length <= 25" style="flex: 1; margin-left: 5px;">{{ rank.teamName
                                }}</div>
                            <div v-else class="scroll-wrap" style="flex: 1; margin-left: 5px;">
                                <div class="scroll-item"> {{ rank.teamName }} </div>
                            </div>

                            <div style="width: 80px; text-align: end;">{{ rank.score }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="display: flex; flex-direction: column; margin: 0 0px 50px 0px; width: 30%; font-size: 24px;">
                <div style="font-weight: 700; text-align: center; color: #e0efc8; margin-bottom: 10px;">
                    <!-- National Monthly Ranking -->
                    Birthday Ranking
                </div>
                <div class="div_background"
                    style="font-size: 16px; background-image: url('img/frame_large.png'); flex: 1; padding: 20px 40px 20px 30px; display: flex; flex-direction: column; justify-content: space-around;">
                    <div v-for="rank in monthRanking" :key="rank.displayOrder"
                        style="display: flex; flex-direction: row; align-items: center;">
                        <div style="width: 30px; text-align: center;"> {{ rank.displayOrder }} </div>
                        <div style="width: 30px; display: flex; align-items: center; padding: 5px 5px 0px 5px;">
                            <img src="img/first.png" v-if="rank.displayOrder == 1"
                                style="width: 100%; height: 150%; object-fit: contain" />
                            <img src="img/second.png" v-if="rank.displayOrder == 2"
                                style="width: 100%; object-fit: contain" />
                            <img src="img/third.png" v-if="rank.displayOrder == 3"
                                style="width: 100%; object-fit: contain" />
                            <img src="img/other.png" v-if="rank.displayOrder > 3"
                                style="width: 100%; object-fit: contain" />
                        </div>

                        <div v-if="rank.teamName.length <= 25" style="flex: 1; margin-left: 5px;">{{ rank.teamName }}
                        </div>
                        <div v-else class="scroll-wrap" style="flex: 1; margin-left: 5px;">
                            <div class="scroll-item"> {{ rank.teamName }} </div>
                        </div>

                        <div style="width: 80px; text-align: end;">{{ rank.score }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $http from '../../http/index.js';
// const host = 'http://localhost:8080/manage';
const host = '';

export default {
    name: 'RankView',
    components: {},
    data() {
        return {
            id: "",
            storeName: "",
            totalRanking: [],
            monthRanking: [],
            dateRanking: [],
            storeRanking: [],

            ranking: {},
            timer: {},
        };
    },
    unmounted() {
        clearInterval(this.timer);
    },
    mounted() {
        this.id = window.location.search.replace("?id=", "");
        if (this.id) {
            this.getData();

            let that = this;
            this.timer =
                setInterval(function () {
                    that.getData();
                }, 60 * 1000);
        }
    },
    methods: {

        /*************** 公共或系统方法 ***************/

        // 提示错误信息
        error(msg) {
            this.$alert(msg, this.$t('public.error.title'), { type: 'error', dangerouslyUseHTMLString: true })
        },

        /*************** 公共或系统方法 ***************/

        // 获得排行版列表
        getData() {
            $http.post(host + '/book/queryRankingNew?id=' + this.id)
                .then(res => {
                    if (res.code == 0) {
                        this.totalRanking = [];
                        this.monthRanking = [];
                        this.sessionRanking = [];
                        this.storeRanking = [];

                        let index = 1;
                        this.totalRanking = res.data.totalRank;
                        this.totalRanking.forEach(item => {
                            item.displayOrder = index++;
                        });

                        index = 1;
                        this.monthRanking = res.data.monthRank;
                        this.monthRanking.forEach(item => {
                            item.displayOrder = index++;
                        });

                        index = 1;
                        this.dateRanking = res.data.dateRank;
                        this.dateRanking.forEach(item => {
                            item.displayOrder = index++;
                        });

                        index = 1;
                        this.storeRanking = res.data.storeRank;
                        this.storeRanking.forEach(item => {
                            item.displayOrder = index++;
                        });

                        this.storeName = res.data.storeName;

                        // } else {
                        //     this.error(this.$t('public.error.execute'));
                    }
                    // }).catch(res => {
                    //     this.error(this.$t('public.error.network'));
                });
        },
    }
};
</script>

<style scoped>
.div_background {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.scroll-wrap {
    max-width: 100%;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
}

.scroll-item {
    animation: scroll linear 4s alternate infinite;
    float: left;
}

@keyframes scroll {
    0% {
        margin-left: 0;
        transform: translateX(0);
    }

    10% {
        margin-left: 0;
        transform: translateX(0);
    }

    90% {
        margin-left: 100%;
        transform: translateX(-100%);
    }

    100% {
        margin-left: 100%;
        transform: translateX(-100%);
    }
}
</style>
